export default {
  title: '404',
  subTitle1: 'No esperábamos esta visita,',
  subTitle2: 'estas opciones podrían ayudarte',
  backButton: {
    link: '/',
    text: 'Regresar',
  },
  coursesButton: {
    link: '/cursos/',
    text: 'Ir al catálogo',
  },
};
