import styled, { createGlobalStyle, keyframes } from 'styled-components';

const theme = {
  media: {
    bigDesktop: '1920px',
    desktop: '1366px',
    laptop: '1280px',
    mediumLaptop: '1180px',
    minLaptop: '1024px',
    tablet: '768px',
    bigMobile: '600px',
    mediumMobile: '480px',
    mobile: '320px',
  },
};

export const GlobalStyle = createGlobalStyle`
  body{
    margin: 0;
    overflow: hidden;
    padding: 0;
    touch-action: none;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left:0;
  }
`;

export const Background = styled.div`
  background-image: url('https://crehana-vercel-images-prd.imgix.net/public/crehana-frontend-mf/automated-v2-000e7d3b-732d-441a-94b3-9acaafefffe6-bg-mobile.jpg?auto=format');
  background-position: center;
  background-size: cover;
  height: 100vh;
  @media (min-width: ${theme.media.tablet}) {
    background-image: url('https://crehana-vercel-images-prd.imgix.net/public/crehana-frontend-mf/automated-v2-d9169ddf-ed94-4135-98dd-8c5863a82db9-bg-tablet.jpg?auto=format');
  }
  @media (min-width: ${theme.media.desktop}) {
    background-image: url('https://crehana-vercel-images-prd.imgix.net/public/crehana-frontend-mf/automated-v2-58c56c59-c990-4970-bd05-0a42e5b05bdd-bg-laptop.jpg?auto=format');
  }
  @media (min-width: ${theme.media.bigDesktop}) {
    background-image: url('https://crehana-vercel-images-prd.imgix.net/public/crehana-frontend-mf/automated-v2-53b3e651-1c34-4172-a027-5ba0e48ebe8d-bg-pc.jpg?auto=format');
  }
`;

export const FadeInAnimation = keyframes`
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
`;

export const Cow = styled.div`
  background-image: url('https://crehana-vercel-images-prd.imgix.net/public/crehana-frontend-mf/automated-v2-9c0cbe5f-c8a3-487f-8313-1749b4b2a4ee-cow-mobile.png?auto=format');
  background-position: center;
  background-size: cover;
  width: 250px;
  height: 350px;
  position: absolute;
  bottom: -20px;
  right: 0;
  animation: ${FadeInAnimation} 2s ease-in-out infinite;
  @media (min-width: ${theme.media.tablet}) {
    background-image: url('https://crehana-vercel-images-prd.imgix.net/public/crehana-frontend-mf/automated-v2-fd1b5e2d-9779-4ae4-b42a-b901ac022bd8-cow-tablet.png?auto=format');
    width: 330px;
    height: 541px;
    right: 5%;
  }
  @media (min-width: ${theme.media.mediumLaptop}) {
    width: 325px;
    height: 530px;
    background-image: url('https://crehana-vercel-images-prd.imgix.net/public/crehana-frontend-mf/automated-v2-cb5060cb-0ecb-4af6-9621-eb56a1446217-cow-laptop.png?auto=format');
    right: 15%;
  }
  @media (min-width: ${theme.media.bigDesktop}) {
    width: 459px;
    height: 802px;
    background-image: url('https://crehana-vercel-images-prd.imgix.net/public/crehana-frontend-mf/automated-v2-ca01a0b0-9fde-4c63-b6c7-eb5bc66f08d1-cow-pc.png?auto=format');
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  @media (min-width: ${theme.media.tablet}) {
    width: 75%;
    margin: 0 auto;
  }
`;

export const MainTitle = styled.div`
  display: none;
  @media (min-width: ${theme.media.tablet}) {
    padding: 50px 20px 30px;
    display: block;
    width: 75%;
    margin: 0 auto;
  }
  @media (min-width: ${theme.media.laptop}) {
    padding: 50px 20px 30px;
  }
  @media (min-width: ${theme.media.desktop}) {
    padding: 70px 20px 30px;
  }
  @media (min-width: ${theme.media.bigDesktop}) {
    padding: 150px 20px 30px;
  }
`;

export const MainTitleMobile = styled.div`
  padding: 30px 20px 30px;
  width: 80%;
  @media (min-width: ${theme.media.tablet}) {
    display: none;
  }
`;

export const Links = styled.div`
  display: block;
  padding: 5px 20px;

  @media (min-width: ${theme.media.tablet}) {
    padding: 10px 20px;
    width: 75%;
    margin: 0 auto;
  }
  @media (min-width: ${theme.media.laptop}) {
    padding: 10px 20px;
  }
  a {
    &:hover {
      cursor: pointer;
    }
  }
`;
