import { AuthNextSeo } from '@/views/Auth/components';

function withSeo<TProps>(Comp: React.FC<React.PropsWithChildren<TProps>>) {
  // TODO: talk with the code owner to fix this code so we can enable this rule again
  // eslint-disable-next-line react/display-name
  return (props: TProps) => (
    <>
      <AuthNextSeo />
      {/* @ts-expect-error */}
      <Comp {...props} />
    </>
  );
}
export default withSeo;
