import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';

import { PageLoader } from '@crehana/compass.ui/v2';
import { useUITheme } from '@crehana/web/Layouts/UITheme';

import { ErrorOverlay } from '@/views/Auth/components';
import AUTH_PAGES_ORG_INFO_QUERY from '@/views/Auth/graphql/AuthPagesOrgInfoQuery.b2b.graphql';
import {
  AuthPagesOrgInfoQuery,
  AuthPagesOrgInfoQueryVariables,
} from '@/views/Auth/graphql/types/AuthPagesOrgInfoQuery';
import Error404 from '@/views/Error404';

function withOrgInfo<TProps>(Comp: React.FC<React.PropsWithChildren<TProps>>) {
  // TODO: talk with the code owner to fix this code so we can enable this rule again
  // eslint-disable-next-line react/display-name
  return (props: TProps) => {
    const router = useRouter();
    const { slug } = router.query as { slug: string };
    const { isDark } = useUITheme();
    const { loading, error, data, refetch } = useQuery<
      AuthPagesOrgInfoQuery,
      AuthPagesOrgInfoQueryVariables
    >(AUTH_PAGES_ORG_INFO_QUERY, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      context: { clientName: 'b2b' },
      variables: {
        slug,
      },
    });

    if (loading) return <PageLoader />;
    if (error)
      return <ErrorOverlay isDark={isDark} onTryAgain={() => refetch()} />;

    if (!data?.organizationInfo) return <Error404 />;

    return <Comp {...props} orgInfo={data?.organizationInfo} />;
  };
}

export default withOrgInfo;
