import { useEffect } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { useAmplitude } from '@crehana/analytics';
import CrehanaLogo from '@crehana/icons/logos/CrehanaLogo';
import { colors, TextLink } from '@crehana/ui';

import data from './Error404.data';
import {
  Background,
  Cow,
  GlobalStyle,
  Header,
  Links,
  MainTitle,
  MainTitleMobile,
} from './Error404.styles';

const NotFound = () => {
  const router = useRouter();
  const { logEvent } = useAmplitude();

  const handleGotoBack = () => {
    if (document.referrer === '') {
      logEvent({
        event: 'Click Catalog 404',
        data: {},
        callback: () => {
          router.push(data.backButton.link);
        },
      });
    } else {
      logEvent({
        event: 'Click Regresar 404',
        data: {},
        callback: () => {
          router.back();
        },
      });
    }
  };

  useEffect(() => {
    logEvent({
      event: 'Render 404 Landing',
      data: {},
    });
  }, []);

  return (
    <Background className="text-white">
      <GlobalStyle />
      <Header>
        <span className="mt-4">
          <CrehanaLogo size={23} color={colors.white} />
        </span>
      </Header>
      <MainTitle>
        <h1 className="font-main-title">{data.title}</h1>
        <p className="font-h4-reg">{data.subTitle1}</p>
        <p className="font-h4-reg">{data.subTitle2}</p>
      </MainTitle>
      <MainTitleMobile>
        <h1 className="font-main-title">{data.title}</h1>
        <p className="font-body2">{data.subTitle1}</p>
        <p className="font-body2">{data.subTitle2}</p>
      </MainTitleMobile>
      <Links>
        <TextLink
          type="button"
          className="text-white block mb-16 underline"
          onClick={handleGotoBack}
        >
          {data.backButton.text}
        </TextLink>
        <Link href={data.coursesButton.link}>
          <TextLink
            as="span"
            className="text-white block underline"
            label={data.coursesButton.text}
          />
        </Link>
      </Links>
      <Cow />
    </Background>
  );
};

export default NotFound;
